import { ACCOUNT_SETTING, SIZE_BANNERS_SETTING } from "@/setting/setting";
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { getAccount } from "@/core-nextv3/account/account.api";
import { getDocument } from "@/core-nextv3/document/document.api";
import { calls, revalidateResults } from "@/core-nextv3/util/call.api";

export default async function withHeader(propsFunc: any)
{

    let props = {};

    const [
        account,
        sizeBanners
    ] = await calls(
        getAccount(ACCOUNT_SETTING),
        getDocument(SIZE_BANNERS_SETTING)
    )

    const revalidateResult = revalidateResults([
        account,
        sizeBanners
    ])

    if (revalidateResult?.revalidate) 
    {
        return {
            props : {
                revalidateWithHeader : revalidateResult?.revalidate,
            }
        }
    }

    props = {
        account     : account?.data,
        sizeBanners : sizeBanners?.data
    };

    const pageStaticProps = await propsFunc(props);
    pageStaticProps.props = { 	
        ...props, 
        ...pageStaticProps.props, 
        ...(await serverSideTranslations(pageStaticProps?.props?.locale || pageStaticProps?.locale || "kr"))
    };

    return pageStaticProps;
}
